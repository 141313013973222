export const environment = {
    production: false,
    clientName:"BJS",
    siteUrl:"https://staging-bjs-rvhost.rivervalleytechnologies.in/",
    apiUrl: "https://staging-bjs-rvhost.rivervalleytechnologies.in/admin/backend/",
    applicationMode:"Staging",
    showAppMode:true,

   
    
};
